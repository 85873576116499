<template>
  <div id="checkItems">
    <CSBreadcrumb/>
    <div class="result-panel">
        <CSTable>
            <template v-slot:thead>
                <tr>
                    <th>检查项</th>
                    <th>完成时间</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr
                    v-for="checkItem in checkItemList"
                    :key="checkItem.id"
                >
                    <td>{{checkItem.name}}</td>
                    <td>{{checkItem.finishedTime || '-'}}</td>
                </tr>
            </template>
        </CSTable>
    </div>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";

import {
  queryInspectLogItemLog
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

export default {
  name: "checkItems",
  components: {
      CSTable,
    CSBreadcrumb,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      // 检查项列表
      checkItemList: [],
    }
  },
  created() {
    this.getCheckItems();
  },
  methods: {
    // 获取检查项
    async getCheckItems() {
      let res = await this.$fly.post(queryInspectLogItemLog,{
        pointLogId: this.id,
      })
      if (res.code != 0) {
        return;
      }
      this.checkItemList = res.data || [];
    }
  },
}
</script>

<style lang="stylus" scoped>

</style>
